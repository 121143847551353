<template>
  <dialogForm
    title="新增"
    btnText="新增(JSON形式)"
    v-bind="$attrs"
    v-on="$listeners"
    v-model="form"
    :btnType="option.btnType"
    :showSuccess="false"
    :option="formOption"
    :beforeOpen="option.beforeOpen"
    @submit="onSubmit"
    @closed="onClosed"
  >
  </dialogForm>
</template>

<script>
import { getOption, example } from './const'
import { isPlainObject } from 'lodash'
import { validatenull } from '@/components/avue/utils/validate'
import { MENU_TYPE_MENU, MENU_TYPE_BTN, MENU_TYPE_OTHER } from '@/utils/constant'
import { MENU_DIC } from '@/utils/constant/menuConst'
import { map, find, isString } from 'lodash'
import { flattenTreeByKey } from '@/utils'

export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },

    type: {
      type: String,
      default: 'batch'
    },

    row: {
      type: Object,
      default: () => {}
    },

    clientType: Number
  },

  data() {
    return {
      form: {},
      formOption: [],
      menuTypeList: [MENU_TYPE_MENU, MENU_TYPE_BTN, MENU_TYPE_OTHER]
    }
  },

  computed: {
    sortNumber() {
      return (data) => (validatenull(data) ? 0 : Math.max(...map(data || [], 'sortNumber')) + 1)
    },

    option({ type }) {
      return {
        batch: {
          btnType: 'primary',
          beforeOpen: () => {
            this.formatJson()
            this.form.sortNumber = this.sortNumber(this.data)
            return true
          }
        },

        single: {
          btnType: 'text',
          beforeOpen: () => {
            this.formatJson()
            const { children, id } = this.row
            this.form.parentId = id
            this.form.sortNumber = this.sortNumber(children)
            return true
          }
        }
      }[type]
    }
  },

  methods: {
    formatJson() {
      this.formOption = getOption.call(this)
      this.form.json = JSON.stringify(example, null, 2)
    },

    handlerError(obj, pos = '') {
      const errorList = []
      if (!isPlainObject(obj)) {
        errorList.push(pos + '.' + obj + '输入必须为对象')
        return errorList
      }

      if (!isString(obj.permissionCode)) {
        errorList.push(`${pos}.${obj.menuName || obj.permissionCode}权限标识需为字符串`)
      }

      if (!obj.menuName) {
        errorList.push(`${pos}.${obj.menuName || obj.permissionCode}菜单名称为空`)
      }

      if (!obj.children) return errorList

      obj.children.forEach((item) => {
        const pos1 = `${pos}.${obj?.menuName || obj?.permissionCode || obj}`.replace(/^\./, '')
        const itemRrrorList = this.handlerError(item, pos1)
        errorList.push(...itemRrrorList)
      })

      return errorList
    },

    handleFormData(obj, parentPermissionCode) {
      if (!this.menuTypeList.includes(obj.menuType)) {
        obj.menuType = MENU_TYPE_BTN
      }

      const { children, permissionCode, requestUrlRegexp } = obj

      if (/^:/.test(permissionCode) && parentPermissionCode != permissionCode) {
        obj.permissionCode = `${parentPermissionCode}${permissionCode}`
      }

      if (requestUrlRegexp) {
        const prefix = MENU_DIC[this.clientType]['requestPrefix']
        const reg = new RegExp(`^((/)*${prefix})?\/*`)
        obj.requestUrlRegexp = requestUrlRegexp.replace(reg, `/${prefix}/`)
      }

      if (validatenull(children)) {
        obj.hasChildren = false
      } else {
        obj.hasChildren = true
        children.forEach((child, index) => {
          child.sortNumber = index + 1
          this.handleFormData(child, obj.permissionCode)
        })
      }

      return obj
    },

    async onSubmit(data, done) {
      const obj = JSON.parse(data.json)
      const errList = this.handlerError(obj)
      if (!validatenull(errList)) {
        this.$message({
          type: 'error',
          message: errList.join('<br>'),
          dangerouslyUseHTMLString: true
        })
        return done(false)
      }
      const json = this.handleFormData(obj, this.getParentPermissionCode(data.parentId) || obj.permissionCode)
      const params = {
        ...data,
        json
      }
      this.$emit('submitHandler', params, done)
    },

    getParentPermissionCode(parentId) {
      if (!parentId) return null
      return find(flattenTreeByKey(this.data), { id: parentId }).permissionCode
    },

    onClosed() {
      Object.assign(this.$data, this.$options.data())
    }
  }
}
</script>

<style scoped lang="scss"></style>
